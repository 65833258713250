<template>
	<div style="background: #fff;padding: 15px;" class="sethadle">
		<!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<div v-if="currentTab==0" class="table-container">
			<p style="color:red;font-size: 14px;">建议上传宽750px，高1040px，图片大小不超过2M</p>
			<div style="display: flex;flex-wrap: wrap;margin-bottom: 40px;margin-top: 20px;" >
				<div style="width: 25%;margin-bottom: 50px;" v-for="(item,index) in imageList1" :key="index">
					<div style="display: flex;flex-direction:column;align-items: center;width: 188px;height: 300px;">
						<img :src="imgUrl+item.PicUrl" alt="" style="width: 188px;height: 266px;">
						<div style="text-align: center;font-size: 14px;color: #F56C6C;padding: 10px 30px;" @click="deleteImg1(index)">删除</div>
					</div>
				</div>
				<!-- <div style="width: 25%;" >
					<div style="display: flex;align-items: center;width: 188px;height: 300px;border: 1px solid #ddd;" >
						<div style="text-align: center;font-size: 12px;color: #409EFF;width: 188px;">+添加图片</div>
					</div>
				</div> -->
				<el-upload style="width: 25%;" :action="imgApi" list-type="picture-card" :on-success="handleAvatarSuccessfu" :file-list="fileList1"
				  accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu" :show-file-list="false">
					<div>
						<i></i>
						<div style="color: #409EFF;font-size: 12px;width:188px;margin-top: 70px;">+添加图片</div>
					</div>
				</el-upload>
			</div>
<!-- 			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination
					v-if="Total"
					style="margin-top:20px;float:right;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="20"
					layout="total, sizes, prev, pager, next, jumper"
					:total="Total"
				></el-pagination>
			</div> -->
		</div>
		
		<div v-else class="table-container">
			<p style="color:red;font-size: 14px;">建议上传宽750px，高1040px，图片大小不超过2M</p>
			<div style="display: flex;flex-wrap: wrap;margin-bottom: 40px;margin-top: 20px;" >
				<div style="width: 25%;margin-bottom: 50px;" v-for="(item,index) in imageList2" :key="index">
					<div style="display: flex;flex-direction: column;align-items: center;width: 188px;height: 300px;">
						<img :src="imgUrl+item.PicUrl" alt="" style="width: 188px;height: 266px;">
						<div style="text-align: center;font-size: 14px;color: #F56C6C;padding: 10px 30px;" @click="deleteImg2(index )">删除</div>
					</div>
				</div>
				<!-- <div style="width: 25%;" >
					<div style="display: flex;align-items: center;width: 188px;height: 300px;border: 1px solid #ddd;" >
						<div style="text-align: center;font-size: 12px;color: #409EFF;width: 188px;">+添加图片</div>
					</div>
				</div> -->
				<el-upload style="width: 25%;" :action="imgApi" list-type="picture-card" :on-success="handleAvatarSuccessfu2" :file-list="fileList2"
				  accept="image/png,image/jpeg,image/jpg,image/gif" :on-remove="handleRemovefu2" :show-file-list="false">
					<div >
						<i></i>
						<div style="color: #409EFF;font-size: 12px;width:188px;margin-top: 70px;">+添加图片</div>
					</div>
				</el-upload>
			</div>
			
	<!-- 		<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination
					v-if="Total1"
					style="margin-top:20px;float:right;"
					@size-change="handleSizeChange1"
					@current-change="handleCurrentChange1"
					:current-page="currentPage1"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="20"
					layout="total, sizes, prev, pager, next, jumper"
					:total="Total1"
				></el-pagination>
			</div> -->
		</div>
		
		<!--  -->
		<el-dialog :visible.sync="deleteDialog" width="500px" center :show-close="false" title="提示">
			<div style="text-align: center;">
				是否确认删除这张海报？
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="deleteDialog = false">取消</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" @click="deleteSure">确认删除</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index';
	import {
		infoposterlist,
		infoposterdelete,
		infoposteradd
	} from "@/api/wyUsedInterface.js"
	export default{
		data(){
			return{
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				imageList1:[],
				fileList1: [],
				imageList2:[],
				fileList2: [],
				listStateList: [{
						id: 0,
						value: '分销邀请海报'
					},
					{
						id: 1,
						value: '商城推广海报'
					}
				],
				listState: '0',
				currentTab: 0, //当前选择的tab
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				deleteDialog:false,
				currentDeleteIndex:0
			};
		},
		beforeMount() {
			this.getsterPic()
		},
		methods:{
			async getsterPic(){
				try{
					let data = {
						IntoPosterType:this.currentTab,
						Skip : 0,
						Take : 1000000,
					}
					let res = await infoposterlist(data)
					if(res.IsSuccess){
						if(this.currentTab==0){
							this.imageList1 = res.Result.Results
							this.Total = res.Result.Total
						}else{
							this.imageList2 = res.Result.Results
							this.Total1 = res.Result.Total1
						}
						//console.log(this.imageList1)
					}
					
				}catch(e){
					//TODO handle the exception
				}
			},
			async deletePosterPic(item){
				try{
					let data = {
						Id :item.Id
					}
					let res = await infoposterdelete(data)
					if(res.IsSuccess){
						this.$message({showClose: true,
							type: 'success',
							message: res.Message ? res.Message : '删除成功'
						});
						this.getsterPic()
					}
					
				}catch(e){
					//TODO handle the exception
				}
			},
			deleteSure(){
				if(this.currentTab==0){
					this.deletePosterPic(this.imageList1[this.currentDeleteIndex])
				}else{
					this.deletePosterPic(this.imageList2[this.currentDeleteIndex])
				}
				this.deleteDialog = false
			},
			deleteImg1(index){
				this.deleteDialog = true
				this.currentDeleteIndex = index
			},
			deleteImg2(index){
				this.deleteDialog = true
				this.currentDeleteIndex = index
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
			
				if (['image/png', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/PNG', 'image/JPG', 'image/JPEG',
						'image/GIF'
					].indexOf(file.raw.type) == -1) {
					this.$message.error('请上传正确的图片格式');
					return false;
				}
				this.addPosterPic(file.response[0])
				// this.imageList1.push(file.response[0])
			},
			
			async addPosterPic(url){
				try{
					let data = {
						IntoPosterType:this.currentTab,
						PicUrl :url
					}
					let res = await infoposteradd(data)
					if(res.IsSuccess){
						this.$message({showClose: true,
							type: 'success',
							message: res.Message ? res.Message : '添加成功'
						});
						this.getsterPic()
					}
					
				}catch(e){
					//TODO handle the exception
				}
			},
			
			handleRemovefu(file, fileList) {
			
				// this.imageList1 = this.imageList1.filter(item => {
				// 	return item != file.response[0]
				// })
			
				console.log(this.imageList1, '-----handleRemovefu------')
			},
			
			//顶部tab切换事件
			filterFun(tab, event) {
				if (tab.index == '0') {
					this.currentTab = 0
				} else if (tab.index == '1') {
					this.currentTab = 1
				}
				this.getsterPic()
			},
			
			//上传图片
			handleAvatarSuccessfu2(res, file) {
			
				if (['image/png', 'image/jpeg', 'image/jpg', 'image/jpeg', 'image/gif', 'image/PNG', 'image/JPG', 'image/JPEG',
						'image/GIF'
					].indexOf(file.raw.type) == -1) {
					this.$message.error('请上传正确的图片格式');
					return false;
				}
				
				this.addPosterPic(file.response[0])
			
				// this.imageList2.push(file.response[0])
				// console.log(this.imageList2, 'kdhahdja')
			},
			handleRemovefu2(file, fileList) {
			
				// this.imageList2 = this.imageList2.filter(item => {
				// 	return item != file.response[0]
				// })
			},
		}
	}
</script>

<style lang="less">
	.sethadle{
		.el-upload--picture-card{
			width: 188px !important;
			height: 266px !important;
		}
	}
	
</style>
